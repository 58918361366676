'use client';

import { getPartnerSettings } from '@/lib/partners/settings/getSettings';
import { useAnalytics } from '@/app/components/Analytics';
import { useTranslation } from '@/app/i18n/client';
import { usePartner } from '@/lib/partners/usePartner/client';

export const ExistingUser = () => {
  const partner = usePartner();
  const partnerSettings: any = getPartnerSettings(partner);
  const { t } = useTranslation();
  const analytics = useAnalytics();

  return (
    <div className="flex items-center justify-center text-textOnPrimary font-light pt-6 pb-2">
      {t('dashboard-sign-in-text')}&nbsp;
      <a
        href={partnerSettings['ace-dashboard-url']}
        className="group font-normal text-core-6 whitespace-nowrap hover:text-core-7 duration-75"
        onClick={() => {
          analytics?.trackButtonClick(
            partner,
            'Already have funding?',
            'ExistingUserBottom-SignIn',
          );
        }}
      >
        <span className="inline-block transform transition-transform pr-2 text-textOnPrimary">
          {t('dashboard-sign-in-link-text')}
          <svg
            width="20"
            height="11"
            viewBox="0 0 14 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="inline ml-1 transition-colors duration-75"
          >
            <path
              d="M6.53678 1L12 5.59491M12 5.59491L6.53678 10.2284M12 5.59491L0.673819 5.59492"
              strokeWidth="1.6"
              className="stroke-textOnPrimary duration-75 group-hover:translate-x-1"
            />
          </svg>
        </span>
      </a>
    </div>
  );
};
